.text-xs {
  font-size: 80%;
}
.text-s {
  font-size: 90%;
}

.app-bg {
  --ion-background-color: #f4f5f7;
}
/* Login Page */
.login-page {
  --color: #333333;
  --background: #f4f5f7;
}
.login-wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2rem;
  margin: 1rem 0;
}
.section-header {
  --background: #f4f5f7;
}

.modal-wrapper {
  padding: 2rem;
}

/* rounded box */
.rounded-container,
.investor-profile-card,
.investment-overview-card {
  border-radius: 5px;
  width: auto;
  background: #ffffff;
  padding: 0.5rem 1rem;
}

/*Transactions Container */
.transaction-container {
  border-radius: 5px;
  width: auto;
  background: #ffffff;
  padding: 0.5rem 1rem;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
}

.alert {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin: 10px 0px;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.search-bar {
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  --border-radius: 10px;
  --box-shadow: none;
  --cancel-button-color: var(--ion-color-primary, #3880ff);
  --clear-button-color: var(--ion-color-step-600, #666666);
  --color: var(--ion-text-color, #000);
  --icon-color: var(--ion-color-step-600, #666666);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 60px;
}

/* Exload styles */
.upload-container {
  background: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  border: 1px dashed #ccc;
}

/* Holdings Widget */
.widget-popover {
  --ion-safe-area-top: 20px;
  --ion-safe-area-top: 20px;
  --ion-safe-area-top: 20px;
  --ion-safe-area-left: 20px;
}

ion-button {
  --box-shadow: none;
}

/* Menu Styles 
 --background: var(--ion-item-background, var(--ion-background-color, #fff));*/
.split-pane-visible > .split-pane-side {
  min-width: 20% !important;
  max-width: 20% !important;
}

ion-menu ion-content {
 --background: var(--ion-color-dark);
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
  background: var(--ion-color-dark);
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
  --background: var(--ion-color-dark);
  color: #ffffff;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #ffffff;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #ffffff;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-light);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-badge {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 5px;
  --padding-bottom: 5px;
}

ion-item {
  --background: #ffffff;
  --background-hover: #ffffff;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th {
  text-align: left;
  padding: 0.5rem 0 !important;
}
td {
  text-align: left;
  padding: 0.25rem 0 !important;
}

.widget {
  --background: #ffffff;
  box-shadow: none;
  width: 100%;
  margin: 10px 5px;
}
.widget-header {
  --background: #d8dbe0;
}

.form-item {
  --background: #ffffff;
}
.form-select {
  margin-top: 10px;
  font-size: 85%;
  border-color: #cccccc;
}

/* Investors Page */
.investor-list {
  background: #ffffff;
  min-height: 360px;
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.investor-list ion-item {
  font-size: 85%;
}
.investor-profile-card,
.investment-overview-card {
  min-height: 150px;
}
.investment-tabs {
  --background: #fff;
}
.amc-icon {
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
.transaction-search-wrapper {
  background-color: #f1f1f1;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.transaction-search-item {
  --background: #f1f1f1;
}
.transaction-search-input,
.transaction-select-input {
  font-size: 90%;
  border: 1px solid #ccc;
  padding: 0 5px;
  border-radius: 5px;
}
.transaction-select-input {
  width: 100%;
  margin-top: 10px;
  padding: 2px 5px;
}
.transaction-filter {
  --min-width: 300px;
}
.transaction-filter .popover-content {
  padding: 2% 1%;
}
.cancellation-modal {
  --max-width: 500px;
  --max-height: 460px;
}
.cancellation-modal .modal-wrapper .ion-page {
  padding: 5% 10%;
}

/* Application Page Styles */

.contentWrapper {
  background: #ffffff;
  box-shadow: none;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;
}

.applicationAvatar {
  width: 100px;
  height: auto;
}

.text-area {
  width: 100%;
  height: 90px;
  font-size: 80%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 1rem;
}
.application-note-entry {
  margin:10px 16px;
  padding: 1rem;
  border-radius: 5px;
  font-size: 90%;
  background-color: #f1f1f1;
}
.application-note-text {
  font-size: 90%;
}
.application-note-audit {
  font-size: 80%;
  color: #999999;
}
.application-summary-list
{
  margin: 0;
}
